<template>
    <div>
        <mt :backType="1">
            <el-form :inline="true" align="left" label-width="120px" :model="customer" :rule="rules" ref="newOrder">
                <el-form-item label="客户名称">
                    <el-input v-model="customer.name" readonly style="width: 160px"></el-input>
                </el-form-item>
                <el-form-item label="客户电话">
                    <el-input v-model="customer.phone" readonly style="width: 160px"></el-input>
                </el-form-item>
                <el-form-item label="支付方式">
                    <el-select v-model="customer.payType" style="width: 160px">
                        <el-option :value="1" label="现金"></el-option>
                        <el-option :value="2" label="银行卡"></el-option>
                        <el-option :value="3" label="微信"></el-option>
                        <el-option :value="4" label="支付宝"></el-option>
                        <el-option :value="5" label="其他"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="客户卡号">
                    <el-input v-model="customer.card" readonly style="width: 200px"></el-input>
                </el-form-item>
                <br>
                <el-form-item label="收件人姓名">
                    <el-input v-model="customer.receiver" style="width: 160px"></el-input>
                </el-form-item>
                <el-form-item label="收件人电话" prop="receiverPhone">
                    <el-input v-model="customer.receiverPhone" style="width: 160px"></el-input>
                </el-form-item>
                <el-form-item label="收货地址">
                    <el-input v-model="customer.receiverAddress" style="width: 480px"></el-input>
                </el-form-item>
                <el-form-item label="备注">
                    <el-input v-model="customer.remark" style="width: 160px"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button @click="orderOk" style="margin-left:60px" type="primary">确认下单</el-button>
                </el-form-item>
            </el-form>
        </mt>
        <el-card style="margin:30px" shadow="always">
            <el-card style="margin-top: 8px" shadow="never">
                <div slot="header" class="clearfix">
                    <el-button @click="addGoods">添加商品</el-button>
                </div>
                <el-table border stripe :data="cart">
                    <el-table-column label="NO." type="index" width="80"></el-table-column>
                    <el-table-column label="商品名称" prop="name"></el-table-column>
                    <el-table-column label="货号" prop="itemNumber">
                        <template slot-scope="scope">
                            <el-input v-model="scope.row.itemNumber"></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column label="参考零售价">
                        <template slot-scope="scope">
                            {{scope.row.price1 | fen2yuan}}
                        </template>
                    </el-table-column>
                    <el-table-column label="实际售价">
                        <template slot-scope="scope">
                            <el-form v-model="scope.row" :model="scope.row">
                                <el-input-number v-model="scope.row.tPrice" :controls="false" :precision="2"
                                                 :min="0" @change="setTprice(scope)"></el-input-number>
                            </el-form>
                        </template>
                    </el-table-column>
                    <el-table-column label="购买数量" width="170px">
                        <template slot-scope="scope">
                            <el-input-number type="number" style="width: 140px" v-model="scope.row.count" :controls="false"
                                             :precision="0" @change="setCount(scope)"></el-input-number>
                        </template>
                    </el-table-column>
                    <el-table-column label="小计">
                        <template slot-scope="scope">
                            {{_sub(scope.row.count,scope.row.tPrice) | money }}
                        </template>
                    </el-table-column>
                    <el-table-column label="备注">
                        <template slot-scope="scope">
                            {{scope.row.tPrice}}
                            <span v-if="scope.row.tPrice==0">赠送</span>
                            <span v-if="(scope.row.tPrice!=0)&&(scope.row.count>0)">销售</span>
                            <span v-if="(scope.row.tPrice!=0)&&(scope.row.count<0)">退货</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <el-button @click="removeFromCart(scope.$index)">移出购物车</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-card>
        </el-card>
    </div>


</template>

<script>
    import mt from "@/components/MainTop.vue"

    export default {
        name: "NewOrder",
        props: ['form'],
        components: {mt},
        data() {
            return {
                goodsMeta: {
                    name: "",
                    page: 0,
                    size: 10,
                    total: 0
                },
                customer: {
                    id: "",
                    name: "",
                    phone: "",
                    card: "",
                    receiver: "",
                    receiverPhone: "",
                    receiverAddress: "",
                    payType: 1,
                    remark: ""
                },
                goods: [],
                cart: [],
                showGoods: true,
                rules: {
                    receiverPhone: [{required: true, message: '请输入正确的客户联系电话', trigger: 'blur', pattern: /^\d{7,11}$/}],
                },
            }
        },
        mounted() {
            if (this.form) {
                localStorage.removeItem("cartGoods");
                //新建下单
                this.customer.id = this.form.id;
                this.customer.name = this.form.name;
                this.customer.phone = this.form.phone;
                this.customer.card = this.form.card;
                this.customer.receiver = this.form.receiver;
                this.customer.receiverPhone = this.form.receiverPhone;
                this.customer.receiverAddress = this.form.receiverAddress;
                this.customer.sellerId = this.form.sellerId;
                this.customer.payType = 1;
                this.customer.remark = "";
            }
            let _cartGoods = JSON.parse(localStorage.getItem("cartGoods") || "{}");
            if (_cartGoods) {
                //添加商品返回
                if (_cartGoods['customer']) {
                    this.customer = _cartGoods.customer;
                }
                if (_cartGoods['cart']) {
                    this.cart = _cartGoods['cart'];
                }
            }
        },

        methods: {
            queryGoods() {
                this.$http.get("/api/goods/find", {params: this.goodsMeta})
                    .then(rsp => {
                        if (rsp.data.status == 200) {
                            this.goods = rsp.data.data || [];
                            this.goodsMeta.total = rsp.data.count;
                        }
                    })
            },
            queryGoodsAddCart() {
                if ((!this.name) || !(this.name.trim())) {
                    return;
                }
                this.$http.get("/api/goods/find", {params: this.goodsMeta})
                    .then(rsp => {
                        if (rsp.data.status == 200) {
                            this.goods = rsp.data.data || [];
                            this.goodsMeta.total = rsp.data.count;
                            this._goodsToAdd(this.goods);
                        }
                    })
            },
            addToCart(row) {
                this._goodsToAdd([row]);
            },
            removeFromCart(row) {
                let a = JSON.parse(JSON.stringify(this.cart));
                a.splice(row, 1);
                this.cart = a;
            },
            orderOk() {//确认订单
                let _this = this;
                localStorage.removeItem("cartGoods");
                this.$refs['newOrder'].validate((valid) => {
                    if (!valid) {
                        return
                    }
                    let param = {
                        customerId: this.customer.id,
                        payType: this.customer.payType,
                        sellerId: this.customer.sellerId,
                        receiver: this.customer.receiver,
                        receiverPhone: this.customer.receiverPhone,
                        receiverAddress: this.customer.receiverAddress,
                        remark: this.customer.remark,
                        goods: this.cart.map(a => {
                            let c = {}
                            c.goodsId = a.id;
                            c.itemNumber = a.itemNumber;
                            c.price1 = a.price1;
                            c.price2 = _this.yuan2fen(a.tPrice);
                            c.count = a.count;
                            return c;
                        })
                    }
                    if (!param.goods.length) {
                        this.$message.error("购物车内没有商品");
                        return;
                    }
                    if (param.goods.find(c => !c.count)) {
                        this.$message.error("购买数量必填且不能为零");
                        return;
                    }
                    this.$http.post("/api/order/create", param)
                        .then((rsp) => {
                            if (rsp.data.status == 200) {
                                this.$confirm('下单成功', '提示', {
                                    confirmButtonText: '继续下单',
                                    cancelButtonText: '返回上一页',
                                    type: 'success'
                                }).then(() => {
                                    this.cart = [];
                                }).catch(() => {
                                    this.$router.go(-1);
                                });
                            }
                        })
                        .catch((rsp) => {
                            this.$message.error(rsp.response.data.message);
                        })

                })
            },
            _goodsToAdd(arr) {
                let ids = this.cart.map(c => c.id);
                let arrToAdd = arr.filter(c => !ids.includes(c.id)).map(c => {
                    c.tPrice = this.fen2yuan(c.price2);
                    c.payType = 1;
                    return c;
                });
                if (arrToAdd.length) {
                    let _a = this.cart.concat(arrToAdd);
                    this.cart = JSON.parse(JSON.stringify(_a));
                }
            },
            _sub(a, b) {
                return a * b;
            },
            setTprice(scope) {
                console.log(scope)
            },
            setCount(scope) {
                console.log(scope)
            },
            addGoods() {
                let _this = this;
                localStorage.setItem("cartGoods", JSON.stringify({customer: _this.customer}));
                this.$router.push({name: 'AddGoods', params: {form: _this.cart}})
            }
        }
    }
</script>

<style scoped>
    .mt .el-form-item {
        margin: 18px auto
    }

    .mt .el-form {
        padding: 18px 0;
    }
</style>
